// import i18n from 'i18next';
// import en from '../constant/lang/en.json';
// import hi from '../constant/lang/hi.json';
// import de from '../constant/lang/de.json';
// import JA from '../constant/lang/JA.json';
// import pt from '../constant/lang/pt.json';
// import TH from '../constant/lang/TH.json';
// import { initReactI18next } from 'react-i18next';

// const resources = {
//   en: {
//     translation: en,
//   },
//   hi: {
//     translation: hi,
//   },
//   de: {
//     translation: de,
//   },
//   JA: {
//     translation: JA,
//   },
//   pt: {
//     translation: pt,
//   },
//   TH: {
//     translation: TH,
//   },
// };

// i18n.use(initReactI18next).init({
//   resources,
//   fallbackLng: 'en',
//   debug: true,
//   interpolation: {
//     escapeValue: false,
//   },
// });

// export default i18n;

import i18n from 'i18next';
import en from '../constant/lang/en.json';
import hi from '../constant/lang/hi.json';
import de from '../constant/lang/de.json';
import pt from '../constant/lang/pt.json';
import ja from '../constant/lang/ja.json';
import th from '../constant/lang/th.json';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  en: { translation: en },
  hi: { translation: hi },
  de: { translation: de },
  ja: { translation: ja },
  pt: { translation: pt },
  th: { translation: th },
};

export const supportedLanguages = Object.keys(resources);

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator'],
      caches: ['localStorage', 'cookie'],
    },
    lng: supportedLanguages.includes(i18n.language) ? i18n.language : 'en',
  });

export default i18n;
