import React from 'react';
import { TEXT } from '../../services/utils';
import { useTranslation } from 'react-i18next';

const NewTag = ({
  style = {},
  handleClick,
  className = '',
  width = 'auto',
  height = 'auto',
  textClassName = '',
}) => {
  const { t } = useTranslation();

  return (
    <button
      onClick={handleClick}
      className={`${className} rounded-md bg-light-yellow px-2 py-1 text-sm font-medium text-white`}
      style={{ width, height, ...style }}
    >
      <p className={`text-lg font-medium text-white ${textClassName}`}>{t(TEXT?.NEW_TAG)}</p>
    </button>
  );
};

export default NewTag;
