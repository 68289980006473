const BASE_URL = 'https://api.ipsubmit.com/';

const API_CONSTANT = {
  BASE_URL: BASE_URL,
  LOGIN: 'api/Login',
  ADD_NEW_TAG: 'api/Tag/NewTag',
  UPDATE_TAG: 'api/Tag/TagUpdate',
  TAG_LIST: 'api/Tag/OpenTagList',
  TAG_DETAILS: 'api/Tag/TagDetail',
  PROCESS_URL_TO_PDF: 'api/ProcessURLtoPDF',
  DASHBOARD_DATA: 'api/ApplicationDashboard',
  APPLICATION_DETAILS: 'api/ApplicationDetail',
  UPDTAE_DOCUMENT: 'api/ApplicationDocsUpdate',
  SHOW_APPLICATION_DOCUMENT: 'api/ShowDocument',
  DASHBOARD_LIST: 'api/ApplicationListDashboard',
  APPLICATION_DATA_ADD: 'api/ApplicationDataAdd',
  TAG_USER_AND_STATUS_LIST: 'api/Tag/TagUserList',
  APPLICATION_DATA: 'api/ApplicationTypeDataEntry',
  UPDATE_APPLICATION_STATUS: 'api/Application_Status_Update',
};

export default API_CONSTANT;
