import React from 'react';
import { TEXT } from '../../services/utils';
import icons from '../../config/assets/icons';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Dialog, TextField, useMediaQuery, useTheme } from '@mui/material';

const RejectApplication = ({
  isOpen,
  loading,
  onReject,
  setIsOpen,
  rejectReason,
  rejectionType,
  setRejectReason,
  setSelectedButtonData,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const fullScreen = useMediaQuery(theme.breakpoints.down('640'));

  const handleCloseModal = () => {
    setIsOpen(false);
    setRejectReason('');
    setSelectedButtonData(null);
  };

  return (
    <div>
      <Dialog
        maxWidth="md"
        open={isOpen}
        fullWidth={true}
        fullScreen={fullScreen}
        onClose={() => handleCloseModal()}
        className="backdrop-blur-[5px]"
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          sx: {
            borderRadius: '15px',
          },
        }}
      >
        <div className="h-full max-h-[550px] overflow-y-auto [&::-webkit-scrollbar]:hidden">
          <div className="border-box-bg-gray sticky top-0 z-30 flex justify-between border-b-[1px] bg-white px-5 py-4">
            <div className="m-auto">
              <h2 className="flex items-center text-center text-xl font-medium text-black">
                {rejectionType === 'application'
                  ? t(TEXT?.REJECT_APPLICATION)
                  : `${t(TEXT?.REJECT)}
                ${t(TEXT?.DOCUMENTS)}`}
              </h2>
            </div>
            <div className="my-auto cursor-pointer" onClick={() => handleCloseModal()}>
              <icons.close w={30} h={30} />
            </div>
          </div>
          <div className="p-6">
            <TextField
              fullWidth
              autoFocus
              value={rejectReason}
              onChange={(e) => setRejectReason(e?.target?.value)}
              label={rejectionType === 'application' ? 'Reject Application' : 'Reject Document'}
              InputProps={{
                style: {
                  height: '50px',
                },
              }}
            />
            <div className="mt-5 flex justify-end gap-2 text-left">
              <button
                onClick={() => handleCloseModal()}
                className="flex w-max items-center justify-center rounded-md border"
              >
                <p className="px-6 py-2 text-sm font-medium text-black sm:text-base">
                  {t(TEXT?.CANCEL)}
                </p>
              </button>
              <button
                onClick={() => onReject()}
                disabled={rejectReason.trim() === '' || loading}
                className={`flex w-max items-center justify-center rounded-md px-6 py-1 ${
                  rejectReason.trim() === '' || loading
                    ? 'cursor-not-allowed bg-gray-400'
                    : 'bg-light-yellow'
                }`}
              >
                {loading ? (
                  <div className="flex w-[50px] items-center justify-center">
                    <CircularProgress size={'1.5rem'} sx={{ color: 'white' }} />
                  </div>
                ) : (
                  <p className="text-sm font-medium text-white sm:text-base">{t(TEXT?.REJECT)}</p>
                )}
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default RejectApplication;
