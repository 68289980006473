import React, { useCallback } from 'react';
import { TEXT } from '../../services/utils';
import { useTranslation } from 'react-i18next';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import LocalOfferRoundedIcon from '@mui/icons-material/LocalOfferRounded';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const DocumentViewer = React.memo(
  ({
    docList,
    setTagData,
    setIsTagOpen,
    setAppDocSummary,
    latestStatusEntry,
    handleDownloadAll,
    setTagSummaryOpen,
    setTagDocumentType,
    setIsDocumentReject,
    setSelectedDocument,
  }) => {
    const { t } = useTranslation();

    // const [activeDocument, setActiveDocument] = useState(null);

    // const handleDocumentChange = (newDoc) => {
    //   if (activeDocument?.fileName === newDoc.fileName) {
    //     setActiveDocument(null);
    //   } else {
    //     setActiveDocument(newDoc);
    //   }
    // };

    const handleReject = useCallback(
      (data) => {
        setIsDocumentReject(true);
        setSelectedDocument(data.currentDocument);
      },
      [setIsDocumentReject, setSelectedDocument],
    );

    const handleOpenTagSummary = React.useCallback(
      (data) => {
        setTagSummaryOpen(true);
        setAppDocSummary(data);
      },
      [setTagSummaryOpen, setAppDocSummary],
    );

    const handleCreateNewTag = React.useCallback(
      (data) => {
        setSelectedDocument(data.currentDocument);
        setTagDocumentType('APD');
        setTagData({
          documentId: data?.currentDocument?.data?.DocumentId,
          tagId: data?.currentDocument?.data?.TagId,
        });
        setIsTagOpen(true);
      },
      [setSelectedDocument, setTagDocumentType, setTagData, setIsTagOpen],
    );

    const CustomHeader = React.useMemo(
      () => (data) => {
        return (
          <div className="z-30 mb-3 flex items-center justify-between bg-gray-100 p-2">
            <h3 className="text-base font-bold text-black">{data?.currentDocument?.fileName}</h3>
            {latestStatusEntry.Status !== 'Filed' && (
              <div>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleReject(data);
                  }}
                  className="mr-2 rounded-md border-[1px] p-2 text-sm font-medium text-black hover:bg-white"
                >
                  <CloseOutlinedIcon className="!mr-1 !h-5 !w-5" />
                  Reject
                </button>

                {data?.currentDocument?.data?.TagId !== 0 ? (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleOpenTagSummary(data);
                    }}
                    className="rounded-md border-[1px] p-2 text-sm font-medium text-black hover:bg-white"
                  >
                    <LocalOfferRoundedIcon className="!mr-1 !h-5 !w-5" />
                    {t(TEXT?.TAG_SUMMARY)}
                  </button>
                ) : (
                  <button
                    onClick={() => handleCreateNewTag(data)}
                    className="rounded-md border-[1px] p-2 text-sm font-medium text-black hover:bg-white"
                  >
                    <LocalOfferOutlinedIcon className="!mr-1 !h-5 !w-5" />
                    New Tag
                  </button>
                )}
              </div>
            )}
          </div>
        );
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [handleReject, handleOpenTagSummary, handleCreateNewTag, t],
    );

    return (
      <div className="flex h-[700px] flex-col rounded-lg border">
        {/* Custom Header */}
        <div className="flex items-center justify-between border-b bg-gray-100 p-2">
          <h2 className="text-lg font-bold">Attachments ({docList?.length})</h2>
          <div className="flex gap-2">
            <button
              onClick={handleDownloadAll}
              className="flex items-center gap-1 rounded-md border-[1px] p-2 text-sm font-medium text-black hover:bg-white"
            >
              <FileDownloadOutlinedIcon className="!h-5 !w-5" />
              <p>Download All</p>
            </button>
          </div>
        </div>

        {/* Main Content */}
        <div className="flex flex-1 overflow-hidden">
          {/* Sidebar */}
          <div className="h-full w-64 overflow-y-auto border-r">
            <div className="space-y-0.5 p-2">
              {docList?.map((doc, ind) => {
                const substringText = doc?.uri.substring(doc?.uri.lastIndexOf('/') + 1);
                const ext = substringText.split('.').pop();
                const truncateText =
                  substringText?.length > 20
                    ? substringText.substring(0, 20) + '...' + ext
                    : substringText;

                return (
                  <div
                    key={ind}
                    // onClick={() => handleDocumentChange(doc)}
                    className="flex w-full cursor-pointer items-center gap-2 rounded p-2 text-sm transition-colors hover:bg-gray-100"
                  >
                    <span className="truncate">{`${ind + 1}). ${truncateText}`}</span>
                  </div>
                );
              })}
            </div>
          </div>

          {/* Document Viewer */}
          <div className="flex-1 overflow-hidden">
            <div className="h-full overflow-y-auto">
              {docList?.map((doc, index) => (
                <div key={index} style={{ marginBottom: '20px' }}>
                  <DocViewer
                    documents={[doc]}
                    pluginRenderers={DocViewerRenderers}
                    config={{
                      header: {
                        overrideComponent: CustomHeader,
                      },
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  },
);

export default React.memo(DocumentViewer);
