import { useTranslation } from 'react-i18next';
import { useNavigate, useResolvedPath } from 'react-router-dom';
import { generateLocalizedPath, ROUTE, TEXT } from '../../services/utils';

const NON = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const resolvedPath = useResolvedPath()?.pathname;
  const firstSegment = resolvedPath ? resolvedPath.split('/')[1] : 'en';

  return (
    <div className="flex h-screen w-full flex-col items-center justify-center">
      <div className="grid h-screen place-content-center bg-white px-4">
        <div className="text-center">
          <h1 className="font-mono text-9xl font-black text-gray-200">404</h1>

          <p className="text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">Oops!</p>

          <p className="mt-1 uppercase text-gray-500">{t(TEXT?.PAGE_NOT_FOUND)}</p>
          <p className="text-red-500">{useResolvedPath()?.pathname}</p>

          <div
            onClick={() => navigate(generateLocalizedPath(firstSegment, ROUTE?.DASHBOARD))}
            className="mt-6 inline-block cursor-pointer rounded bg-indigo-600 px-5 py-3 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring"
          >
            Go Back Home
          </div>
        </div>
      </div>
    </div>
  );
};
export default NON;
