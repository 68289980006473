import {
  TEXT,
  ROUTE,
  S_TEXT,
  SESSION_KEY,
  getFromAsync,
  setInAsyncStorage,
  generateLocalizedPath,
} from '../../services/utils';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { MODE } from '../../services/enum';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { postLoginAPI } from '../../store/login/action';
import TextInput from '../../components/inputs/TextInput';

const Login = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();

  useEffect(() => {
    if (getFromAsync(SESSION_KEY.USER_DATA)?.IsSuccess) {
      navigate(
        generateLocalizedPath(localStorage.getItem(SESSION_KEY.I_18_NEXT_LANG), ROUTE?.DASHBOARD),
        { replace: true },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isFormShow, setIsFormShow] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleAction = () => {
    setIsLoading(true);
    if (username.trim() !== '' && password.trim() !== '') {
      const apiObj = {
        Mode: MODE.WEB_MODE,
        UserName: username.trim(),
        Password: password.trim(),
      };

      postLoginAPI(dispatch, apiObj)
        .then(({ data }) => {
          setInAsyncStorage(SESSION_KEY.USER_DATA, data);
          if (data?.IsSuccess) {
            navigate(
              generateLocalizedPath(
                localStorage.getItem(SESSION_KEY.I_18_NEXT_LANG),
                ROUTE?.DASHBOARD,
              ),
            );
            setIsFormShow(data?.IsSuccess);
            i18n?.changeLanguage(data?.LanguageCode);
          }
        })
        .catch((err) => {
          toast.error(err?.data?.ErrorMessage);
          setPassword('');
        })
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
      toast.error(S_TEXT?.BLANK_LOGIN_ERROR);
    }
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
      >
        <section className="bg-gray-50">
          <div className="mx-auto flex h-screen flex-col items-center justify-center px-6 py-8 lg:py-0">
            <div className="w-full rounded-lg bg-white shadow sm:max-w-md md:mt-0 xl:p-0">
              <div className="space-y-4 p-6 sm:p-8 md:space-y-6">
                <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                  {!isFormShow ? t(TEXT?.LOGIN_TEXT) : t(TEXT?.SELECT_FORM_TO_CONT)}
                </h1>
                <form>
                  <div className="space-y-4 md:space-y-6">
                    <div className="w-full">
                      <TextInput
                        id="email"
                        fullWidth={true}
                        value={username}
                        label="Username"
                        variant="standard"
                        onChange={(e) => setUsername(e.target.value)}
                      />
                    </div>
                    <div className="w-full">
                      <TextInput
                        id="password"
                        label="Password"
                        fullWidth={true}
                        value={password}
                        variant="standard"
                        show={showPassword}
                        type={showPassword ? 'text' : 'password'}
                        onShow={() => setShowPassword(!showPassword)}
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyDownCapture={(e) => e.key === 'Enter' && handleAction()}
                      />
                    </div>
                    <div className="flex items-center justify-end">
                      <p className="cursor-pointer text-sm font-medium text-blue-400 hover:underline">
                        {t(TEXT?.FORGOT_PASS)}
                      </p>
                    </div>
                    <button
                      disabled={isLoading}
                      onClick={handleAction}
                      className={`${isLoading ? 'bg-gray-300 px-5 py-2' : 'bg-main px-5 py-2'} rounded-lg text-sm font-medium text-white focus:outline-none`}
                    >
                      {isLoading ? (
                        <div className="flex w-[36px] items-center justify-center">
                          <CircularProgress size={'1.5rem'} />
                        </div>
                      ) : (
                        <p className="text-sm font-medium text-white sm:text-base">
                          {t(TEXT?.LOGIN)}
                        </p>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </motion.div>
    </AnimatePresence>
  );
};

export default Login;
