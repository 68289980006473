import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  TEXT,
  ROUTE,
  SESSION_KEY,
  getFromAsync,
  getUrlParams,
  generateLocalizedPath,
  getEncodedLastSegmentFromURL,
} from '../../services/utils';
import {
  AddEditTag,
  DownloadProcess,
  AppBackGroundDocs,
  RejectApplication,
} from '../../components/modal';
import dayjs from 'dayjs';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { MODE } from '../../services/enum';
import { useTranslation } from 'react-i18next';
import { post } from '../../services/ApiServices';
import NewTag from '../../components/buttons/NewTag';
import API_CONSTANT from '../../services/ApiConstant';
import { HeaderSimple } from '../../components/header';
import { ComponentLoading } from '../../components/loading';
import TagSummary from '../../components/tagSummary/TagSummary';
import { setDocURLSList } from '../../store/application/reducer';
import { tagUserAndStatusListAPI } from '../../store/tags/action';
import TagSummaryModal from '../../components/modal/TagSummaryModal';
import { decrypt, encrypt } from '../../services/encryptDecryptService';
import { useNavigate, useParams, useResolvedPath } from 'react-router-dom';
import DocumentViewer from '../../components/documentViewer/DocumentViewer';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const AppDetails = ({ value, label, showList = false }) => {
  return (
    <div className="flex pb-2">
      <div className="w-36 text-left sm:w-48">
        <p className="text-sm text-font-grey sm:text-base">{label}</p>
      </div>
      <div className="mx-5 border-r-2" />
      {showList ? (
        <div className="w-full text-left">
          {value && value.length > 0 && (
            <div className="space-y-1">
              {value?.map((term, index) => (
                <p key={index}>{term}</p>
              ))}
            </div>
          )}
        </div>
      ) : (
        <div className="w-full text-left">
          <p className="text-sm font-medium sm:text-base">{value}</p>
        </div>
      )}
    </div>
  );
};

const ApplicationDetails = ({ applicationId }) => {
  const { lang } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const path = useResolvedPath();
  const navigate = useNavigate();
  const userData = getFromAsync(SESSION_KEY.USER_DATA);
  const isTagDetailsScreen = path?.pathname.includes(ROUTE?.TAG_DETAILS);

  if (!path?.pathname.includes(ROUTE?.TAG_DETAILS)) {
    const encodedLastSegment = path?.pathname ? getEncodedLastSegmentFromURL(path?.pathname) : null;
    var paramData = getUrlParams({ data: encodedLastSegment });
    var appData = decrypt(paramData);
  }

  const [isOpen, setIsOpen] = useState(false);
  const [tagData, setTagData] = useState(null);
  const [isTagOpen, setIsTagOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [isAppBGDoc, setIsAppBGDoc] = useState(false);
  const [appBGDocData, setAppBgDocData] = useState([]);
  const [rejectReason, setRejectReason] = useState('');
  const [appDocSummary, setAppDocSummary] = useState([]);
  const [tagDocumentType, setTagDocumentType] = useState('');
  const [applicationData, setApplicationData] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [isDocumentReject, setIsDocumentReject] = useState(false);
  const [selectedButtonData, setSelectedButtonData] = useState(null);
  const [isApplicationReject, setIsApplicationReject] = useState(null);
  const [documentRejectionReason, setDocumentRejectionReason] = useState('');
  const [isDocumentRejectLoading, setIsDocumentRejectLoading] = useState(false);
  const [isApplicationRejectLoading, setIsApplicationRejectLoading] = useState(false);

  const latestStatusEntry = applicationData?.ApplicationFillingStatusList?.reduce(
    (latest, current) => {
      return new Date(current.AddDateTime) > new Date(latest.AddDateTime) ? current : latest;
    },
  );

  useEffect(() => {
    getAppData();
    tagUserAndStatusListAPI(dispatch, { ApplicationId: appData?.data || applicationId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAppData = () => {
    setIsLoading(true);

    const apiObj = {
      Mode: MODE.WEB_MODE,
      AppUserId: userData?.AppUserId,
      InputText: encrypt({ ApplicationId: appData?.data || applicationId }),
    };

    post(API_CONSTANT.APPLICATION_DETAILS, apiObj)
      .then((res) => {
        const data = decrypt(res.data.OutPutText);
        dispatch(setDocURLSList(data));
        setApplicationData(data);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        toast.error(e?.data?.ErrorMessage || e?.message || String(e));
      });
  };

  const _onUpdateStatus = (buttonData) => {
    setIsApplicationRejectLoading(true);

    const apiObj = {
      AppUserId: userData?.AppUserId,
      InputText: encrypt({
        RejectReason: rejectReason,
        ButtonValue: buttonData?.ButtonValue,
        ApplicationId: appData?.data || applicationId,
      }),
      Mode: MODE.WEB_MODE,
    };

    post(API_CONSTANT.UPDATE_APPLICATION_STATUS, apiObj)
      .then((res) => {
        const data = decrypt(res.data.OutPutText);
        if (data?.IsUpdated) {
          setRejectReason('');
          setSelectedButtonData(null);
          setIsApplicationReject(false);
          toast.success(data?.Message || t(TEXT?.APP_DATA_UPDATE_MESSAGE), { autoClose: 1000 });
          setTimeout(() => {
            navigate(generateLocalizedPath(lang, ROUTE?.DASHBOARD));
          }, 2000);
          setIsApplicationRejectLoading(false);
        }
      })
      .catch((e) => {
        setIsApplicationRejectLoading(false);
        toast.error(e?.data?.ErrorMessage || e?.message || String(e));
      });
  };

  const onRejectDocument = () => {
    setIsDocumentRejectLoading(true);

    const apiObj = {
      Mode: MODE.WEB_MODE,
      AppUserId: userData?.AppUserId,
      InputText: encrypt({
        RejectReason: documentRejectionReason,
        ApplicationDocsId: selectedDocument?.data?.DocumentId,
      }),
    };

    post(API_CONSTANT?.UPDTAE_DOCUMENT, apiObj)
      .then((res) => {
        const data = decrypt(res?.data?.OutPutText);
        if (data?.Isupdated) {
          toast.success(data?.Message, { autoClose: 1000 });
          setIsDocumentReject(false);
          setSelectedButtonData(null);
          setDocumentRejectionReason('');
          setIsDocumentRejectLoading(false);
          getAppData();
        }
      })
      .catch((e) => {
        toast.error(e?.message || e?.data?.ErrorMessage);
      })
      .finally(() => {
        setIsDocumentRejectLoading(false);
      });
  };

  // const downloadSingleDocument = (val) => {
  //   setIsDownload(true);

  //   const apiObj = {
  //     AppUserId: userData?.AppUserId,
  //     Mode: MODE?.WEB_MODE,
  //     InputText: encrypt({ DocumentId: val?.DocumentId }),
  //   };

  //   post(API_CONSTANT.SHOW_APPLICATION_DOCUMENT, apiObj)
  //     .then((res) => {
  //       if (res?.data?.IsSuccess) {
  //         const data = decrypt(res?.data?.OutPutText);
  //         if (data) {
  //           setIsDownload(false);
  //           const slug = data?.FileName.split('.').pop();
  //           const LINK = `data:${slug};base64`;
  //           const linkSource = `${LINK},${data?.DocumentBase64String}`;
  //           const downloadLink = document.createElement('a');
  //           const fileName = data?.FileName;
  //           downloadLink.href = linkSource;
  //           downloadLink.download = fileName;
  //           downloadLink.click();
  //         }
  //       }
  //     })
  //     .catch((e) => {
  //       setIsDownload(false);
  //       toast.error(e.message || e?.response?.data?.Message);
  //     });
  // };

  const downloadAndZipMultipleFiles = useCallback(async () => {
    const zip = new JSZip();
    const zipName = `${applicationData?.MarkText || ''}_${applicationData?.ApplicationNumber || ''}_${latestStatusEntry.Status || ''}_${dayjs().format('YYYY_MM_DD_hh:mm')}`;

    try {
      setIsDownload(true);
      const filePromises = applicationData?.ListOfURLs?.map(async (file, index) => {
        const substringText =
          file?.FileURL.substring(file?.FileURL.lastIndexOf('/') + 1) || `file_${index + 1}`;

        // Fetch the file from the URL
        const response = await fetch(API_CONSTANT?.BASE_URL + 'api/' + file?.FileURL);
        if (!response.ok) {
          toast.error(`Failed to fetch ${substringText}: ${response.statusText}`);
        } else {
          const fileData = await response.blob();

          // Detect the file extension from the file URL
          const fileExtension = substringText.split('.').pop() || 'bin';

          // Check if the file name already contains the extension
          const fileNameWithExtension = substringText.includes(`.${fileExtension}`)
            ? substringText
            : `${substringText}.${fileExtension}`;

          zip.file(fileNameWithExtension, fileData);
        }
      });

      await Promise.all(filePromises);

      const zipBlob = await zip.generateAsync({ type: 'blob' });

      saveAs(zipBlob, zipName || 'documents.zip');
      setIsDownload(false);
    } catch (error) {
      setIsDownload(false);
      toast.error(
        error.message || error?.response?.data?.Message || 'Error downloading or zipping files.',
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationData]);

  // const downloadDocuments = (val, isBulk = false) => {
  //   if (isBulk) {
  //     downloadAndZipMultipleFiles();
  //   } else {
  //     downloadSingleDocument(val);
  //   }
  // };

  const memoizedDocs = useMemo(() => {
    return applicationData?.ListOfURLs?.map((doc) => {
      const fileName = doc?.FileURL.substring(doc?.FileURL.lastIndexOf('/') + 1);
      return {
        uri: `${API_CONSTANT?.BASE_URL}api/${doc?.FileURL}`,
        fileName,
        data: doc,
      };
    });
  }, [applicationData?.ListOfURLs]);

  const handleDownloadAll = useCallback(() => {
    downloadAndZipMultipleFiles();
  }, [downloadAndZipMultipleFiles]);

  const setTagDataCallback = useCallback(setTagData, [setTagData]);

  return (
    <div className="h-full w-full bg-ghost-white">
      {!isTagDetailsScreen && <HeaderSimple title={t(TEXT?.APPLICATION_DETAILS)} home />}

      <div className="bg-white">
        {isLoading ? (
          <ComponentLoading loading={isLoading} className="pt-3" />
        ) : (
          <>
            <div
              className={`${isTagDetailsScreen ? 'px-0' : 'px-8'} rounded-t-3xl bg-white pt-2 sm:pt-5`}
            >
              <div
                className={`${isTagDetailsScreen ? 'border-b-0' : 'border-b-[1px]'} flex flex-wrap items-start justify-between pb-2 lg:flex-nowrap`}
              >
                <div className="w-full lg:w-3/4">
                  {latestStatusEntry?.Status && (
                    <AppDetails label={'Latest Status'} value={latestStatusEntry.Status} />
                  )}
                  {latestStatusEntry?.Status && (
                    <AppDetails label={'Updated'} value={latestStatusEntry.AddDateTimeText} />
                  )}
                  {applicationData?.ApplicationNumber && (
                    <AppDetails
                      label={t(TEXT?.APPLICATION_NUMBER)}
                      value={applicationData?.ApplicationNumber}
                    />
                  )}
                  {applicationData?.AddUser && (
                    <AppDetails label={t(TEXT?.ADDED_BY)} value={applicationData?.AddUser} />
                  )}

                  {applicationData?.Email && (
                    <AppDetails label={t(TEXT?.EMAIL)} value={applicationData?.Email} />
                  )}
                  {applicationData?.City && (
                    <AppDetails label={t(TEXT?.CITY)} value={applicationData?.City} />
                  )}
                  {applicationData?.MarkText && (
                    <AppDetails label={t(TEXT?.MARK_TEXT)} value={applicationData?.MarkText} />
                  )}
                  {applicationData?.OwnerName && (
                    <AppDetails label={t(TEXT?.OWNER_NAME)} value={applicationData?.OwnerName} />
                  )}
                  {applicationData?.RegularDeadline && (
                    <AppDetails
                      label={t(TEXT?.DEFAULT_DEADLINE)}
                      value={applicationData?.RegularDeadline}
                    />
                  )}
                  {applicationData?.ExpressDeadline && (
                    <AppDetails
                      label={t(TEXT?.REQUESTED_DEADLINE)}
                      value={
                        applicationData?.ExpressDeadline && dayjs(applicationData?.ExpressDeadline)
                      }
                    />
                  )}
                  {applicationData?.SearchFor && (
                    <AppDetails
                      showList={true}
                      label={t(TEXT?.SEARCH_FOR)}
                      value={applicationData?.SearchFor}
                    />
                  )}
                </div>

                <div className="my-3 w-full lg:my-0 lg:w-auto">
                  <div className="mb-2 flex gap-2">
                    <button
                      onClick={() => downloadAndZipMultipleFiles()}
                      className="flex items-center gap-1 rounded-md border-[1px] py-2 pl-1 pr-2 text-base font-medium text-black hover:bg-gray-100"
                    >
                      <FileDownloadOutlinedIcon className="!h-5 !w-5" />
                      <p>Download All</p>
                    </button>
                  </div>
                  {applicationData?.TagId !== 0 ? (
                    <TagSummary data={applicationData} />
                  ) : (
                    <div className="flex w-full justify-center sm:justify-normal">
                      {latestStatusEntry.Status !== 'Filed' && (
                        <NewTag
                          width="100%"
                          handleClick={() => {
                            setSelectedDocument({
                              fileName: '',
                            });
                            setTagDocumentType('AP');
                            setTagData({
                              documentId: applicationData?.ApplicationId,
                              tagId: applicationData?.TagId,
                            });
                            setIsTagOpen(true);
                          }}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="mx-8 border-b-2" />

            <section
              className={`${isTagDetailsScreen ? 'px-0' : ' px-8'} body-font py-8 text-gray-600`}
            >
              <div className="flex flex-col items-center">
                {applicationData?.MarkKeywordList !== null &&
                  applicationData?.MarkKeywordList?.length !== 0 && (
                    <>
                      <div className="w-full text-start">
                        <p className="pl-1 text-base font-semibold">{t(TEXT?.MARK_KEYWORD_LIST)}</p>
                      </div>
                      <div className="group w-full overflow-x-auto [&::-webkit-scrollbar]:hidden">
                        <div className="flex gap-1.5 py-2">
                          {applicationData?.MarkKeywordList?.map((mark, index) => (
                            <div
                              key={index}
                              className="group/item relative mx-1 inline-block cursor-pointer rounded-lg border p-2.5 text-left transition-all duration-200 hover:bg-gray-100/50 hover:shadow-md"
                            >
                              <div className={`flex flex-col ${mark.Added ? 'gap-2' : 'gap-0'}`}>
                                <span className="font-medium text-gray-800 transition-colors">
                                  {mark.Keyword}
                                </span>
                                <span className="flex items-center gap-2 text-sm text-gray-500 group-hover/item:text-gray-700">
                                  {mark.Added}
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </>
                  )}

                {applicationData?.AppBackGround !== null &&
                  applicationData?.AppBackGround?.length !== 0 && (
                    <>
                      <div className="mt-4 w-full text-start">
                        <p className="pb-2 pl-1 text-base font-semibold">
                          {t(TEXT?.APP_BACKGROUND)}
                        </p>
                      </div>
                      <div className="w-full overflow-x-auto [&::-webkit-scrollbar]:hidden">
                        <div className="flex gap-4">
                          {applicationData?.AppBackGround?.map((data, ind) => (
                            <div
                              key={ind}
                              className="flex min-w-[200px] max-w-[250px] cursor-pointer flex-col gap-1 rounded-lg border bg-white p-3 shadow-sm transition-all hover:shadow-md"
                            >
                              <div
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setIsAppBGDoc(true);
                                  setAppBgDocData(data);
                                }}
                                className="flex flex-grow flex-col"
                              >
                                <h3 className="text-base font-semibold text-gray-900">
                                  {data?.Background}
                                </h3>
                                <p className="text-sm text-gray-600">{data?.MarkKeyword}</p>
                              </div>
                              <div className="mt-auto">
                                {data?.TagId !== 0 ? (
                                  <button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setIsOpen(true);
                                      setAppDocSummary(data);
                                    }}
                                    className="block w-full rounded-md border px-3 py-1.5 text-sm font-medium text-black hover:bg-gray-100/70"
                                  >
                                    {t(TEXT?.TAG_SUMMARY)}
                                  </button>
                                ) : (
                                  <div className="flex justify-center">
                                    {latestStatusEntry.Status !== 'Filed' && (
                                      <NewTag
                                        width="100%"
                                        textClassName="text-sm font-medium !text-black"
                                        className="block w-full rounded-md border !bg-white px-3 py-1.5 hover:!bg-gray-100/70"
                                        handleClick={() => {
                                          setTagDocumentType('ABG');
                                          setSelectedDocument({
                                            ...data?.BackGroundDocs[0],
                                            fileName: data?.BackGroundDocs[0]?.NameOfFile,
                                          });
                                          setTagData({
                                            documentId: data?.AppBackgroundId,
                                            tagId: data?.TagId,
                                          });
                                          setIsTagOpen(true);
                                        }}
                                      />
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </>
                  )}
              </div>

              <div className="my-5">
                <DocumentViewer
                  docList={memoizedDocs}
                  setIsTagOpen={setIsTagOpen}
                  setTagSummaryOpen={setIsOpen}
                  setTagData={setTagDataCallback}
                  setAppDocSummary={setAppDocSummary}
                  latestStatusEntry={latestStatusEntry}
                  handleDownloadAll={handleDownloadAll}
                  setTagDocumentType={setTagDocumentType}
                  setIsDocumentReject={setIsDocumentReject}
                  setSelectedDocument={setSelectedDocument}
                />
              </div>
            </section>

            <div className="mb-2 mt-5 flex justify-center gap-3 py-2 text-left sm:mb-5">
              {applicationData?.Buttons?.length > 0 &&
                applicationData?.Buttons?.map((ele) => {
                  return (
                    <button
                      onClick={() => {
                        setSelectedButtonData(ele);
                        if (ele?.ButtonValue === 1) {
                          setIsApplicationReject(true);
                        } else {
                          _onUpdateStatus(ele);
                        }
                      }}
                      className="flex w-max items-center justify-center rounded-md bg-light-yellow px-6 py-2"
                    >
                      <p className="text-lg font-medium text-white">{ele?.ButtonText}</p>
                    </button>
                  );
                })}
            </div>
          </>
        )}
      </div>

      {isTagOpen && (
        <AddEditTag
          data={tagData}
          isOpen={isTagOpen}
          tagFor={tagDocumentType}
          setIsOpen={setIsTagOpen}
          getAppDataApi={getAppData}
          setIsAppBGDoc={setIsAppBGDoc}
          setSelectedDocument={setSelectedDocument}
          title={{ selectedDocument, marktext: applicationData?.MarkText }}
        />
      )}

      {(isDocumentReject || isApplicationReject) && (
        <RejectApplication
          setSelectedButtonData={setSelectedButtonData}
          rejectionType={isDocumentReject ? 'document' : 'application'}
          isOpen={isDocumentReject ? isDocumentReject : isApplicationReject}
          rejectReason={isDocumentReject ? documentRejectionReason : rejectReason}
          setIsOpen={isDocumentReject ? setIsDocumentReject : setIsApplicationReject}
          loading={isDocumentReject ? isDocumentRejectLoading : isApplicationRejectLoading}
          setRejectReason={isDocumentReject ? setDocumentRejectionReason : setRejectReason}
          onReject={isDocumentReject ? onRejectDocument : () => _onUpdateStatus(selectedButtonData)}
        />
      )}

      {isOpen && <TagSummaryModal data={appDocSummary} isOpen={isOpen} setIsOpen={setIsOpen} />}

      {isDownload && <DownloadProcess isOpen={isDownload} />}

      {isAppBGDoc && (
        <AppBackGroundDocs
          tagData={tagData}
          isOpen={isAppBGDoc}
          appData={appBGDocData}
          setTagData={setTagData}
          setIsOpen={setIsAppBGDoc}
          getAppDataApi={getAppData}
          setIsTagOpen={setIsTagOpen}
          setAppData={setAppBgDocData}
          selectedDocuments={selectedDocument}
          setSelectedDocument={setSelectedDocument}
        />
      )}
    </div>
  );
};

export default ApplicationDetails;
