import dayjs from 'dayjs';
import { MODE } from './enum';
import { post } from './ApiServices';
import { toast } from 'react-toastify';
import { SESSION_KEY, getFromAsync } from './utils';
import { getFormattedDate } from '../constant/indxex';
import { decrypt, encrypt } from './encryptDecryptService';

export const getRules = (field) => {
  let rules = field?.rules;
  [('maxLength', 'minLength', 'max', 'min', 'required')].forEach((e) => {
    rules?.[e]?.value && (rules[e].value = JSON.parse(rules[e].value));
  });

  rules?.pattern?.value && (rules.pattern.value = new RegExp(rules?.pattern?.value));

  if (rules && (field.type === 'search' || field.type === 'checkbox')) {
    rules.validate = (v) => {
      switch (true) {
        case rules?.minLength?.message && v?.length < (rules?.minLength?.value || 0):
          return rules?.minLength?.message;
        case rules?.maxLength?.message && v?.length > (rules?.maxLength?.value || 0):
          return rules?.maxLength?.message;
        case !v?.length && rules?.required?.message:
          return rules?.required?.message;
        default:
          return true;
      }
    };
  }

  return rules?.required || rules?.pattern ? rules : {};
  // return rules?.required ? rules : {};
};

export const getFormValues = (val, formData) =>
  Object.keys(val).map((Id) => ({
    Id,
    Value: Array.isArray(val[Id])
      ? JSON.stringify(
          val[Id]?.map((f) => {
            const dateObj = Object.keys(f).find((_d) => dayjs(f[_d]).isValid());
            switch (true) {
              case !!f?.base64:
                return { FileData: f?.base64?.split(',')[1], Filename: f?.name };
              case !!f?.desc:
                return { Id: f?.id, Value: f?.desc };
              case !!dateObj:
                return { ...f, [dateObj]: getFormattedDate(f[dateObj]) };
              default:
                return f;
            }
          }),
        )
      : val[Id]?.toISOString
        ? getFormattedDate(val[Id], formData?.find((d) => d?.id === Id)?.format)
        : String(val[Id] || ''),
  }));

export const getSearchApiData = async (endPoint, TextSearch) =>
  new Promise((resolve) => {
    !TextSearch && resolve(null);
    post(endPoint, {
      Mode: MODE?.WEB_MODE,
      InputText: encrypt({ TextSearch }),
      AppUserId: getFromAsync(SESSION_KEY.USER_DATA)?.AppUserId,
    })
      .then(({ data }) => {
        resolve(
          decrypt(data.OutPutText)?.TradeMarkList?.map((e) => ({
            id: e?.NiceCode,
            title: e?.NiceCode,
            class: e?.Class_Id,
            desc: e?.Description,
          })) || [],
        );
      })
      .catch((e) => toast.error(e?.ErrorMessage || e?.data?.ErrorMessage || e?.Message));
  });

export const loadFile = async (files, field) => {
  const rules = getRules(field);

  if (!rules) {
    throw new Error('Invalid field rules');
  }

  const { resolution, size } = rules;

  return Promise.all(
    Object.values(files).map((file) => {
      return new Promise((resolve, reject) => {
        if (!file.type.startsWith('image/')) {
          file.base64 = null;
          file.fileType = file?.name?.split('.')?.pop();
          if (file.size / 1024 > size?.value) {
            reject(size?.message || 'File size exceeds limit');
          } else {
            resolve(file);
          }
          return;
        }

        const reader = new FileReader();

        reader.onload = async ({ target }) => {
          try {
            const img = new Image();
            img.onload = () => {
              const canvas = document.createElement('canvas');
              canvas.width = img.width;
              canvas.height = img.height;
              canvas.getContext('2d').drawImage(img, 0, 0);

              const pngBase64 = canvas.toDataURL('image/jpeg');
              file.base64 = pngBase64;
              file.fileType = file?.name?.split('.')?.pop();

              if (
                (resolution?.value?.width && img.width > resolution.value.width) ||
                (resolution?.value?.height && img.height > resolution.value.height)
              ) {
                reject(resolution.message || 'Image resolution exceeds limit');
              } else if (file.size / 1024 > size?.value) {
                reject(size?.message || 'File size exceeds limit');
              } else {
                resolve(file);
              }
            };

            img.onerror = (e) => reject(e);
            img.src = target.result;
          } catch (error) {
            reject('Error processing file');
          }
        };

        reader.onerror = () => reject('File reading failed');
        reader.readAsDataURL(file);
      });
    }),
  );
};

// FIXME: we are now using api to convert url to pdf
// import { toJpeg } from 'html-to-image';
// import { regExp } from '../constant';
// export const getUrlData = async (input) =>
//   new Promise(async (resolve, reject) => {
//     try {
//       const page = await fetch(input);
//       const blob = await page.blob();
//       const fs = new FileReader();
//       fs.onload = async ({ target: { result } }) => {
//         const webUrlLoad = document.getElementById('webUrlLoad');
//         document.head.innerHTML += result?.match(regExp.removeStyle)?.join('');
//         webUrlLoad.innerHTML = result
//           .replace(regExp.removeStyle, '')
//           .replace(regExp.link, `<link crossorigin="anonymous"`);
//         setTimeout(() => {
//           toJpeg(webUrlLoad)
//             .then(resolve)
//             .catch(reject)
//             .finally(() => (webUrlLoad.innerHTML = null));
//         }, 500);
//       };
//       fs.onerror = reject;
//       fs.readAsBinaryString(blob);
//     } catch (error) {
//       console.log('🚀 ~ file: dynamicFormHelper.js:113 ~ newPromise ~ error:', error);
//       reject(error);
//     }
//   });
