import React from 'react';
import NON from '../screens/NON';
import Login from '../screens/login';
import TagList from '../screens/tags';
import NewForm from '../screens/form';
import Preview from '../screens/preview';
import Dashboard from '../screens/dashboard';
import DocPreview from '../screens/docPreview';
import ErrorBoundary from '../components/error';
import TagDetails from '../screens/tags/tagDetails';
import { supportedLanguages } from '../services/i18n';
import ApplicationDetails from '../screens/application';
import GoogleDocViewer from '../screens/docPreview/googleDoc';
import { ROUTE, SESSION_KEY, getFromAsync } from '../services/utils';
import { Outlet, Navigate, RouterProvider, createBrowserRouter, useParams } from 'react-router-dom';

const ValidateLanguage = ({ children }) => {
  const { lang } = useParams();
  if (!supportedLanguages.includes(lang)) {
    return <NON />;
  }
  return children;
};

const RequireAuth = () =>
  getFromAsync(SESSION_KEY.USER_DATA)?.IsSuccess ? <Outlet /> : <Navigate to="/" replace />;

const router = createBrowserRouter([
  { path: ROUTE.LOGIN, element: <Login /> },
  {
    path: ROUTE.PREVIEW + '/*',
    element: (
      <ErrorBoundary>
        <Preview />
      </ErrorBoundary>
    ),
  },
  {
    path: '/:lang',
    element: (
      <ValidateLanguage>
        <RequireAuth />
      </ValidateLanguage>
    ),
    children: [
      { path: ROUTE.TAG_LIST, element: <TagList /> },
      { path: ROUTE.DASHBOARD, element: <Dashboard /> },
      { path: ROUTE.FORM + '/*', element: <NewForm /> },
      { path: ROUTE.TAG_DETAILS + '/*', element: <TagDetails /> },
      { path: ROUTE.DOC_PREVIEW + '/*', element: <DocPreview /> },
      { path: ROUTE.GOOGLE_DOC_VIEW, element: <GoogleDocViewer /> },
      { path: ROUTE.APP_DETAILS + '/*', element: <ApplicationDetails /> },
    ],
    ErrorBoundary,
  },
  {
    path: '/*',
    element: <NON />,
  },
]);

const Navigation = () => <RouterProvider router={router} />;

export default Navigation;
