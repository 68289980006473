import React, { memo, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { TextBox } from 'devextreme-react';
import icons from '../../config/assets/icons';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import TextArea from 'devextreme-react/text-area';
import { post } from '../../services/ApiServices';
import API_CONSTANT from '../../services/ApiConstant';
import { DOC_NUMBERS, MODE } from '../../services/enum';
import useMediaQuery from '@mui/material/useMediaQuery';
import { CircularProgress, Dialog } from '@mui/material';
import { DevDropDown } from '../../components/devExtremeFields';
import { SESSION_KEY, TEXT, getFromAsync } from '../../services/utils';
import { decrypt, encrypt } from '../../services/encryptDecryptService';

const AddEditTag = memo(
  ({
    data,
    title,
    isOpen,
    tagFor,
    setIsOpen,
    getAppDataApi,
    setIsAppBGDoc,
    setSelectedDocument,
  }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { tag } = useSelector((state) => state);
    const fullScreen = useMediaQuery(theme.breakpoints.down('640'));

    const [tagText, setTagText] = useState('');
    const [tagSummary, setTagSummary] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [selectedToUser, setSelectedToUser] = useState([]);

    useEffect(() => {
      setTagText(title?.selectedDocument?.fileName || '');
    }, [title]);

    useEffect(() => {
      if (tag?.tagMasterData?.TagUserList?.length > 0) {
        setSelectedToUser(tag?.tagMasterData?.TagUserList[0]);
      }
    }, [tag?.tagMasterData?.TagUserList]);

    const _onAddEditTag = () => {
      setIsLoading(true);
      const userData = getFromAsync(SESSION_KEY?.USER_DATA);

      const obj = {
        TagText: tagText,
        TagSummary: tagSummary,
        DocumentId: data?.documentId,
        ToUser: selectedToUser?.UserId,
        DocumentNumber: DOC_NUMBERS?.find((doc) => doc.id === tagFor)?.id,
      };

      const apiObj = {
        AppUserId: userData?.AppUserId,
        Mode: MODE?.WEB_MODE,
        InputText: encrypt(obj),
      };

      post(API_CONSTANT?.ADD_NEW_TAG, apiObj)
        .then((res) => {
          const data = decrypt(res.data.OutPutText);
          if (data?.IsCreated) {
            setTagText('');
            setTagSummary('');
            setIsOpen(false);
            setIsAppBGDoc(false);
            setSelectedToUser([]);
            toast.success(data?.Message, { autoClose: 900 });
            setTimeout(() => {
              getAppDataApi();
            }, 1000);
          }
        })
        .catch((e) => {
          toast.error(e?.data?.ErrorMessage || e?.message || String(e));
        })
        .finally(() => setIsLoading(false));
    };

    const _onClose = () => {
      setIsOpen(false);
      setSelectedDocument(null);
    };

    return (
      <div>
        <Dialog
          maxWidth="md"
          open={isOpen}
          fullWidth={true}
          fullScreen={fullScreen}
          onClose={() => _onClose()}
          className="backdrop-blur-[5px]"
          aria-labelledby="responsive-dialog-title"
          PaperProps={{
            sx: {
              borderRadius: '15px',
            },
          }}
        >
          <div className="h-full max-h-[550px] overflow-y-auto [&::-webkit-scrollbar]:hidden">
            <div className="border-box-bg-gray sticky top-0 flex justify-between border-b-[1px] bg-white px-5 py-4">
              <div className="m-auto">
                <h2 className="flex items-center text-center text-xl font-medium text-black">
                  {`${t(TEXT?.NEW_TAG)}${title ? ` - ${title?.marktext}${title?.selectedDocument?.fileName ? `_${title?.selectedDocument?.fileName}` : ''}` : ''}`}
                </h2>
              </div>
              <div className="my-auto cursor-pointer" onClick={() => _onClose()}>
                <icons.close w={30} h={30} />
              </div>
            </div>
            <div className="p-4">
              <div className="flex w-full flex-col items-center">
                <div className="w-full">
                  <DevDropDown
                    height={45}
                    customImage={false}
                    labelMode="floating"
                    searchEnabled={true}
                    value={selectedToUser}
                    displayValue="UserName"
                    label={t(TEXT?.TO_USER)}
                    data={tag?.tagMasterData?.TagUserList}
                    onSelectionChanged={(e) => setSelectedToUser(e?.selectedItem)}
                  />
                </div>
                <div className="w-full">
                  <TextBox
                    value={tagSummary}
                    labelMode="floating"
                    label={t(TEXT?.TAG_SUMMARY)}
                    className="!h-[45px] !w-full"
                    onValueChanged={(e) => setTagSummary(e?.value)}
                  />
                </div>
              </div>
              <div className="mt-3">
                <TextArea
                  value={tagText}
                  minHeight={100}
                  labelMode="floating"
                  autoResizeEnabled={true}
                  label={t(TEXT?.TAG_TEXT)}
                  valueChangeEvent="change"
                  onValueChanged={(e) => setTagText(e?.value)}
                  onInitialized={(e) => {
                    setTimeout(function () {
                      e.component.focus();
                    }, 0);
                  }}
                />
              </div>
              <div className="mb-2 mt-5 flex justify-center py-2 text-left sm:mb-5">
                <button
                  disabled={isLoading}
                  onClick={() => _onAddEditTag()}
                  className={`${isLoading ? 'bg-gray-300' : 'bg-light-yellow'} flex w-max items-center justify-center rounded-md px-6 py-2`}
                >
                  {isLoading ? (
                    <div className="flex w-[37.47px] items-center justify-center">
                      <CircularProgress size={'1.5rem'} sx={{ color: 'white' }} />
                    </div>
                  ) : (
                    <p className="text-sm font-medium text-white sm:text-base">{t(TEXT?.SAVE)}</p>
                  )}
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    );
  },
);

export default AddEditTag;
