const MODE = {
  WEB_MODE: 1,
  MOBILE_APP_MODE: 2,
};

const USER_LEVEL = {
  USER_LEVEL: 5,
  ADMIN_LEVEL: 10,
};

const DOC_NUMBERS = [
  { id: 'AP', name: 'Application Master' },
  { id: 'AD', name: 'App Data' },
  { id: 'APD', name: 'Application Docs' },
  { id: 'ABG', name: 'App Background' },
  { id: 'ABGD', name: 'Application BackGround Docs' },
];

export { MODE, USER_LEVEL, DOC_NUMBERS };
