import { useState } from 'react';
import Images from '../../config/Images';
import { Controller } from 'react-hook-form';
import { ComponentLoading } from '../loading';
import { Stack, TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { getRules, loadFile } from '../../services/dynamicFormHelper';

const renderImage = {
  pdf: Images?.PDF,
  txt: Images?.TXT,
  doc: Images?.DOC,
  ppt: Images?.PPT,
  docx: Images?.DOC,
  pptx: Images?.PPT,
  xls: Images?.EXCEL,
  xlsx: Images?.EXCEL,
};

export const FileUploadField = ({ field, formControl, setShowSnack }) => {
  const [loading, setLoading] = useState(false);

  return (
    <Controller
      name={field?.id}
      rules={getRules(field)}
      control={formControl.control}
      render={({ fieldState: { error }, field: { value, name, onBlur, onChange } }) => (
        <div className="rounded-lg border p-3">
          <p className="mb-1">{field?.label}</p>
          {!loading && !!value?.length && (
            <Stack direction={'column'} className="h-full max-h-[800px] w-full overflow-y-auto">
              {value?.map((e, i) => (
                <div className="flex items-center justify-between py-1">
                  <div className="flex items-center">
                    <img
                      alt={name}
                      key={name + i}
                      id={name + 'file' + i}
                      src={renderImage[e?.fileType] || e?.base64}
                      className={
                        'mr-2 !h-[70px] !min-h-[70px] !w-[70px] !min-w-[70px] overflow-hidden rounded-lg ' +
                        (renderImage[e.fileType]
                          ? ' object-contain '
                          : ' border border-gray-400 object-cover ')
                      }
                    />
                    <p>{e?.name}</p>
                  </div>
                  <IconButton
                    aria-label="delete"
                    className="cursor-pointer"
                    onClick={() => onChange(value.filter((_e) => _e?.name !== e.name))}
                  >
                    <HighlightOffIcon color="error" sx={{ fontSize: 28 }} />
                  </IconButton>
                </div>
              ))}
            </Stack>
          )}
          <ComponentLoading loading={loading} className={'h-40'} />
          <TextField
            id={name}
            name={name}
            type="file"
            size="small"
            error={!!error}
            onBlur={onBlur}
            disabled={loading}
            className="!mt-2 !hidden w-full"
            helperText={error?.message || null}
            inputProps={{
              accept: field?.accept,
              className: '!py-2 !h-7',
              multiple: field?.multiple,
            }}
            onChange={({ target: { files } }) => {
              if (files?.length) {
                setLoading(true);
                const newFiles = Array.from(files);

                loadFile(newFiles, field)
                  .then((processedFiles) => {
                    if (field?.multiple) {
                      onChange([...(value || []), ...processedFiles]);
                    } else {
                      onChange(processedFiles.slice(0, 1));
                    }
                  })
                  .catch((e) => setShowSnack({ type: 'error', message: e }))
                  .finally(() => setLoading(false));
              }
            }}
          />

          <div className="!mt-2 h-full w-full rounded-md border border-gray-400 px-[14px] py-3">
            <label
              for={name}
              className="cursor-pointer rounded-[2.5px] border-[0.33px] border-black bg-dark-white px-2 py-[4px]"
            >
              Choose files
            </label>
          </div>
        </div>
      )}
    />
  );
};
