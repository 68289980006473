import React, { useEffect, useState } from 'react';
import {
  TEXT,
  ROUTE,
  SESSION_KEY,
  generateUrl,
  getFromAsync,
  removeFromAsync,
  generateLocalizedPath,
} from '../../services/utils';
import { toast } from 'react-toastify';
import Images from '../../config/Images';
import 'devextreme/dist/css/dx.light.css';
import { MODE } from '../../services/enum';
import { useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { LOGOUT } from '../../store/login/action';
import { post } from '../../services/ApiServices';
import Header from '../../components/header/Header';
import icons from '../../config/assets/icons/index';
import API_CONSTANT from '../../services/ApiConstant';
import { useDispatch, useSelector } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';
import { fetchTagListAPI } from '../../store/tags/action';
import { ComponentLoading } from '../../components/loading';
import i18n, { supportedLanguages } from '../../services/i18n';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { decrypt, encrypt } from '../../services/encryptDecryptService';
import DataGrid, { Pager, Paging, Column } from 'devextreme-react/data-grid';

const Dashboard = () => {
  const { lang } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { tag } = useSelector((state) => state);

  const theme = useTheme();
  const userData = getFromAsync(SESSION_KEY.USER_DATA);
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  // const [dayWiseRecords, setDayWiseRecords] = useState([]);
  const [applicationList, setApplicationList] = useState([]);

  useEffect(() => {
    const langCode = lang;
    const langLower = langCode.toLowerCase();
    if (supportedLanguages.some((l) => l.toLowerCase() === langLower)) {
      i18n.changeLanguage(langCode);
    } else {
      i18n.changeLanguage('en');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    getApplicationData();
    fetchTagListAPI(dispatch);
    // getDayWiseRecords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // const getDayWiseRecords = () => {
  //   setIsLoading(true);

  //   const apiObj = {
  //     Mode: MODE.WEB_MODE,
  //     AppUserId: userData?.AppUserId,
  //   };

  //   post(API_CONSTANT.DASHBOARD_DATA, apiObj)
  //     .then(({ data }) => {
  //       setDayWiseRecords(decrypt(data?.OutPutText)?.ResultList);
  //       setIsLoading(false);
  //     })
  //     .catch((e) => {
  //       setIsLoading(false);
  //       toast.error(e?.message || e?.data?.ErrorMessage);
  //     });
  // };

  const getApplicationData = () => {
    setIsLoading(true);

    const apiObj = {
      Mode: MODE.WEB_MODE,
      AppUserId: userData?.AppUserId,
    };

    post(API_CONSTANT.DASHBOARD_LIST, apiObj)
      .then(({ data }) => {
        setApplicationList(decrypt(data?.OutPutText)?.ResultList);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        toast.error(e?.message || e?.data?.ErrorMessage);
      });
  };

  const handleLogout = () => {
    LOGOUT(dispatch);
    navigate(ROUTE?.LOGIN);
    removeFromAsync(SESSION_KEY.USER_DATA);
  };

  const renderData = ({ data }) => {
    return (
      <div
        onClick={() => {
          window.open(
            generateUrl(
              ROUTE.APP_DETAILS,
              encrypt({
                data: data?.ApplicationId,
              }),
            ),
            '_blank',
          );
        }}
        className="flex cursor-pointer justify-between border-b-[1px] border-light-blue-border px-2 py-3"
      >
        <div className="text-start">
          <p className="text-base font-semibold text-font-black">{data?.ApplicationName}</p>
          <p className="text-sm font-medium text-dark-slat">{data?.UserName}</p>
          {data?.CustomerName && (
            <p className="text-sm font-medium text-dark-slat">{data?.CustomerName}</p>
          )}
        </div>
        <div className="text-end">
          {data?.StatusText && (
            <div className="rounded-full bg-light-grey px-[6px] py-[5px] text-center">
              <p className="text-xs font-semibold text-font-black">{data?.StatusText}</p>
            </div>
          )}
          <p className="text-sm font-semibold text-font-black">{data?.MarkText}</p>
          <p className="text-xs font-semibold tracking-[0.2px] text-font-black">{data?.Deadline}</p>
          <p className="text-xs font-medium text-dark-slat">{data?.AgoText}</p>
        </div>
      </div>
    );
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
      >
        <div className="bg-ghost-white">
          <Header
            width={125}
            height={125}
            img={Images.Logo}
            matches={matches}
            searchValue={search}
            onLogout={() => handleLogout()}
            subTitle={userData?.LastLoginAt}
            title={userData?.WelcomeMessage}
            onChange={(e) => setSearch(e.target.value)}
          />
          <div className="flex items-center justify-between px-7 py-3">
            <div className="text-left">
              <p className="text-font-black-light text-base font-semibold sm:text-xl">
                {userData?.WelcomeMessage}!
              </p>
              <p className="text-base font-semibold text-font-grey sm:text-xl">
                {t(TEXT?.LAST_LOGIN_AT)} {userData?.LastLoginAt}
              </p>
            </div>
            <div className="mb-2 mt-5 flex justify-center py-2 text-left sm:mb-5">
              <button
                onClick={() => window.open(ROUTE?.TAG_LIST, '_blank')}
                className="flex w-max items-center justify-center gap-1 rounded-lg bg-light-blue px-4 py-2"
              >
                <p className="text-sm font-medium text-white sm:text-base">{t(TEXT?.TAG_LIST)}</p>
                {(tag?.tagList?.OpenTagsCount ||
                  tag?.tagList?.RepliedTagCount ||
                  tag?.tagList?.ReplyAwaitedCount) && (
                  <p className="text-sm font-medium text-white sm:text-base">
                    (
                    {tag?.tagList?.OpenTagsCount +
                      tag?.tagList?.RepliedTagCount +
                      tag?.tagList?.ReplyAwaitedCount}
                    )
                  </p>
                )}
              </button>
            </div>
          </div>

          <div className="flex flex-1 items-center justify-center bg-white text-left">
            {isLoading ? (
              <ComponentLoading loading={isLoading} className="mt-3" />
            ) : (
              <div className="w-full">
                {/* {userData?.UserLevel === USER_LEVEL?.ADMIN_LEVEL && (
                  <div className="m-8 flex items-center rounded-xl bg-grey px-8">
                    <div className="w-[168px] py-6 pl-0 pr-0 text-center sm:pl-6">
                      <div className="h-[36px]" />
                      <div className="mt-[16px]">
                        <p className="flex h-[48px] items-center text-end text-sm font-medium text-font-black">
                          {t(TEXT?.LAST_30_DAYS)}
                        </p>
                        <p className="flex h-[48px] items-center text-end text-sm font-medium text-font-black">
                          {t(TEXT?.LAST_90_DAYS)}
                        </p>
                      </div>
                    </div>
                    <div className="flex w-[-webkit-fill-available] items-center overflow-x-auto md:w-full xl:justify-around">
                      {dayWiseRecords?.map((list, i) => {
                        return (
                          <>
                            <div className="w-[168px] p-6 text-center">
                              <div className="w-max rounded bg-extra-light-blue p-2 min-[815px]:w-full">
                                <p className="text-sm font-semibold text-light-blue">
                                  {list?.Header}
                                </p>
                              </div>
                              <div className="mt-[16px]">
                                <p className="py-4 pt-0 text-2xl font-bold">
                                  {list?.Last30DaysRecords}
                                </p>
                                <p className="text-2xl font-bold">{list?.Last90DaysRecords}</p>
                              </div>
                            </div>
                            <div
                              className={`${
                                dayWiseRecords?.length - 1 === i ? 'hidden' : 'block'
                              } h-[114px] w-[1px] border-[1px] border-light-blue-border`}
                            />
                          </>
                        );
                      })}
                    </div>
                  </div>
                )} */}

                <div className="m-8 flex items-center rounded-xl bg-grey">
                  <div className="p-6">
                    <p className="mb-2 text-2xl font-bold">
                      {t(TEXT?.NEW)} {t(TEXT?.FORM)}
                    </p>
                    <div className="flex w-full cursor-pointer flex-wrap items-center">
                      {userData?.ApplicationTypeList?.map((list) => {
                        return (
                          <div
                            key={list?.ApplicationTypeId}
                            onClick={() =>
                              navigate(
                                generateUrl(
                                  generateLocalizedPath(lang, ROUTE?.FORM),
                                  encrypt({ data: list }),
                                ),
                              )
                            }
                            className="mr-4 mt-3 flex w-full items-center justify-between rounded-xl border-[1px] px-4 py-[13px] md:w-[240px]"
                          >
                            <p className="text-sm font-medium text-slat-blue">
                              {list?.ApplicationTypeName}
                            </p>
                            <icons.rightArrow w={10} h={10} />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>

                <div className="flex flex-wrap items-center rounded-xl">
                  {applicationList?.map((list) => {
                    return (
                      <div
                        key={list?.Header}
                        className="m-8 mt-0 w-full rounded-2xl bg-grey px-[23px] py-4 lg:w-[355px]"
                      >
                        <div className="w-full rounded-lg bg-extra-light-blue px-2 py-5 lg:w-[310px]">
                          <p className="text-center text-base font-semibold text-light-blue">
                            {list?.Header}
                          </p>
                        </div>

                        {list?.ApplicationList !== null && list?.ApplicationList?.length !== 0 ? (
                          <DataGrid
                            showColumnHeaders={false}
                            className="application-datagrid"
                            dataSource={list?.ApplicationList}
                          >
                            <Paging defaultPageSize={5} />
                            <Pager
                              visible={true}
                              displayMode={'compact'}
                              showNavigationButtons={true}
                            />
                            <Column
                              width={'100%'}
                              alignment="left"
                              allowEditing={false}
                              cellRender={renderData}
                            />
                          </DataGrid>
                        ) : (
                          <div className="flex h-full min-h-[506px] w-full items-center justify-center">
                            <p className="text-base font-normal sm:text-lg sm:font-semibold">
                              {t(TEXT?.NO_DATA_FOUND)}
                            </p>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default Dashboard;
