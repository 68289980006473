import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tagList: [],
  tagData: {},
  tagMasterData: {},
};

const tagDataSlice = createSlice({
  name: 'tag',
  initialState,
  reducers: {
    setTagData(state, action) {
      state.tagData = action.payload;
    },
    setTagList(state, action) {
      state.tagList = action.payload;
    },
    setTagMasterData(state, action) {
      state.tagMasterData = action.payload;
    },
    clearTagData(state) {
      state.tagList = [];
      state.tagData = {};
      state.tagMasterData = {};
    },
  },
});

export const { setTagList, setTagData, setTagMasterData, clearTagData } = tagDataSlice.actions;
export default tagDataSlice;
