import { toast } from 'react-toastify';
import { MODE } from '../../services/enum';
import { post } from '../../services/ApiServices';
import API_CONSTANT from '../../services/ApiConstant';
import { setTagList, setTagMasterData } from './reducer';
import { getFromAsync, SESSION_KEY } from '../../services/utils';
import { decrypt, encrypt } from '../../services/encryptDecryptService';

const fetchTagListAPI = (dispatch) => {
  const userData = getFromAsync(SESSION_KEY.USER_DATA);

  return new Promise((resolve, reject) => {
    post(`${API_CONSTANT.TAG_LIST}`, {
      Mode: MODE.WEB_MODE,
      AppUserId: userData?.AppUserId,
    })
      .then((res) => {
        const decrayptedData = decrypt(res?.data?.OutPutText);
        dispatch(setTagList(decrayptedData));
        resolve(res);
      })
      .catch((err) => {
        toast.error(err?.data?.ErrorMessage || err?.message || String(err));
        reject(err);
      });
  });
};

const tagUserAndStatusListAPI = (dispatch, data) => {
  const userData = getFromAsync(SESSION_KEY.USER_DATA);
  return new Promise((resolve, reject) => {
    post(`${API_CONSTANT.TAG_USER_AND_STATUS_LIST}`, {
      Mode: MODE.WEB_MODE,
      AppUserId: userData?.AppUserId,
      InputText: encrypt(data),
    })
      .then((res) => {
        const decrayptedData = decrypt(res?.data?.OutPutText);
        dispatch(setTagMasterData(decrayptedData));
        resolve(res);
      })
      .catch((err) => {
        toast.error(err?.data?.ErrorMessage || err?.message || String(err));
        reject(err);
      });
  });
};

export { fetchTagListAPI, tagUserAndStatusListAPI };
