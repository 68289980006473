import React, { useEffect, useState } from 'react';
import {
  TEXT,
  ROUTE,
  SESSION_KEY,
  getFromAsync,
  generateLocalizedPath,
} from '../../services/utils';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import Images from '../../config/Images';
import { MODE } from '../../services/enum';
import { TextArea } from 'devextreme-react';
import { useTranslation } from 'react-i18next';
import ApplicationDetails from '../application';
import { post } from '../../services/ApiServices';
import API_CONSTANT from '../../services/ApiConstant';
import { useDispatch, useSelector } from 'react-redux';
import { HeaderSimple } from '../../components/header';
import IconImage from '../../components/image/IconImage';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchTagListAPI } from '../../store/tags/action';
import { ComponentLoading } from '../../components/loading';
import TagSummary from '../../components/tagSummary/TagSummary';
import { decrypt, encrypt } from '../../services/encryptDecryptService';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const TagDetails = () => {
  const { lang } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { tag } = useSelector((state) => state);
  const userData = getFromAsync(SESSION_KEY?.USER_DATA);

  const [tagText, setTagText] = useState('');
  const [tagDetails, setTagDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getTagDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTagDetails = () => {
    setIsLoading(true);

    const apiObj = {
      Mode: MODE.WEB_MODE,
      AppUserId: userData?.AppUserId,
      InputText: encrypt({ TagId: tag?.tagData?.TagId }),
    };

    post(API_CONSTANT.TAG_DETAILS, apiObj)
      .then((res) => {
        const data = decrypt(res.data.OutPutText);
        setTagDetails(data);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        toast.error(e?.data?.ErrorMessage || e?.message || String(e));
      });
  };

  const _onEditTag = (statusText) => {
    setIsLoading(true);

    const statusId = tag?.tagMasterData?.TagStatusList?.filter(
      (x) => x?.StatusText?.toLowerCase() === statusText?.toLowerCase(),
    );

    const obj = {
      TagText: tagText,
      TagStatus: statusId[0]?.StatusId,
      TagId: tagDetails?.TagDetail?.TagId,
      ToUser: tagDetails?.TagDetail?.ToUserId,
      TagSummary: tagDetails?.TagDetail?.TagSummary,
      DocumentId: tagDetails?.TagDetail?.DocumentId,
      DocumentNumber: tagDetails?.TagDetail?.DocumentNumber,
    };

    const apiObj = {
      Mode: MODE?.WEB_MODE,
      InputText: encrypt(obj),
      AppUserId: userData?.AppUserId,
    };

    post(API_CONSTANT.UPDATE_TAG, apiObj)
      .then((res) => {
        const data = decrypt(res.data.OutPutText);
        if (data?.IsUpdated) {
          setTagText('');
          setIsLoading(false);
          toast.success(data?.Message, { autoClose: 900 });
          fetchTagListAPI(dispatch);
          setTimeout(() => {
            navigate(generateLocalizedPath(lang, ROUTE?.TAG_LIST));
          }, 1000);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        toast.error(e?.data?.ErrorMessage || e?.message || String(e));
      });
  };

  const getAttachmentDownload = async () => {
    const slug = tagDetails?.TagDetail?.AttachmentFileName.split('.').pop();
    const LINK = `data:${slug};base64`;
    const linkSource = `${LINK},${tagDetails?.TagDetail?.Attachment}`;
    const downloadLink = document.createElement('a');
    const fileName = tagDetails?.TagDetail?.AttachmentFileName;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  return (
    <div className="h-full w-full">
      <div className="sticky top-0 z-30">
        <HeaderSimple
          path={`${generateLocalizedPath(lang, ROUTE?.TAG_LIST)}`}
          title={tag?.tagData?.TagNumber + ' : ' + tag?.tagData?.TagSummary}
        />
      </div>

      {isLoading ? (
        <ComponentLoading loading={isLoading} className="bg-white pt-3" />
      ) : (
        <div className="rounded-t-3xl bg-white px-8">
          <div className="w-full border-b-[1px] border-b-extra-dark-grey py-2">
            <div className="flex w-full flex-wrap py-1 sm:flex-nowrap sm:py-2">
              <div className="w-full text-left md:sm:w-2/4">
                {tagDetails?.TagDetail?.DocumentStatus && (
                  <p className="mr-4 w-max text-sm font-bold text-dark-black sm:text-lg">
                    {t(TEXT?.TAG_STATUS)} :
                    <span className="ml-1 text-xs font-normal text-dark-black sm:text-base">
                      {tagDetails?.TagDetail?.TagStatus}
                    </span>
                  </p>
                )}

                {tagDetails?.TagDetail?.TagSummary && (
                  <p className="flex-1 flex-col justify-center overflow-hidden text-ellipsis whitespace-nowrap text-sm font-bold sm:w-4/6 sm:text-lg">
                    {t(TEXT?.TAG_SUMMARY)} :
                    <span className="ml-1 text-xs font-normal text-dark-black md:text-base">
                      {tagDetails?.TagDetail?.TagSummary}
                    </span>
                  </p>
                )}

                {tagDetails?.TagDetail?.TagNumber && (
                  <p className="text-sm font-bold sm:text-lg">
                    {t(TEXT?.TAG_NUMBER)} :
                    <span className="ml-1 text-xs font-normal text-dark-black md:text-base">
                      {tagDetails?.TagDetail?.TagNumber}
                    </span>
                  </p>
                )}

                {tagDetails?.TagDetail?.DocumentStatus && (
                  <p className="mr-4 w-max text-sm font-bold text-dark-black sm:text-lg">
                    {t(TEXT?.DOCUMENT_STATUS)} :
                    <span className="ml-1 text-xs font-normal text-dark-black sm:text-base">
                      {tagDetails?.TagDetail?.DocumentStatus}
                    </span>
                  </p>
                )}
              </div>

              <div className="w-full text-right md:w-2/4">
                {tagDetails?.TagDetail?.Created && (
                  <div className="mt-2 flex justify-start sm:justify-end">
                    <p className="whitespace-pre text-sm font-medium sm:text-base">
                      {dayjs(tagDetails?.TagDetail?.Created).format('DD/MM/YYYY')}
                    </p>
                  </div>
                )}

                {tagDetails?.TagDetail?.CreatedBy && (
                  <div className="flex w-full justify-start sm:justify-end">
                    <div className="mt-3 items-center sm:mt-[7px]">
                      <p className="mr-2 text-xs font-medium sm:text-base">
                        {t(TEXT?.CREATED)} {t(TEXT?.BY)}
                      </p>
                    </div>
                    <div className="mt-1 flex items-center">
                      <IconImage name={Images.UserCircle} isEye width={30} height={20} />
                      <p className="ml-2 mt-[0.8px] text-xs font-medium sm:text-lg">
                        {tagDetails?.TagDetail?.CreatedBy}
                      </p>
                    </div>
                  </div>
                )}

                {tagDetails?.TagDetail?.DocumentNumber && (
                  <div className="flex justify-start sm:justify-end">
                    <p className="w-full text-sm font-bold text-dark-black sm:text-lg">
                      {t(TEXT?.DOCUMENT_NUMBER)} :
                      <span className="ml-1 text-xs font-normal text-dark-black md:text-base">
                        {tagDetails?.TagDetail?.DocumentNumber}
                      </span>
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="w-auto py-2 text-center">
            <p className="text-font-black-light w-full text-base font-bold sm:text-xl">
              {t(TEXT?.TAG_DETAILS)}
            </p>
          </div>

          <div
            className={`${tagDetails?.TagDetail?.TranslationText ? 'justify-center' : 'justify-between'} flex w-full border-t-[1px] border-t-extra-dark-grey py-2`}
          >
            {tagDetails?.TagDetail && (
              <div className="w-full text-end sm:w-[600px]">
                <TagSummary data={tagDetails?.TagDetail} />
              </div>
            )}

            {!tagDetails?.TagDetail?.TranslationText && (
              <div className="w-[40%] space-y-2 text-start">
                <h3 className="text-sm font-bold text-dark-black sm:text-lg">Translation</h3>
                <div className="rounded-md bg-gray-50 p-2">
                  <div
                    id="section-to-print"
                    className="whitespace-pre-wrap text-left text-base font-normal text-gray-700"
                    dangerouslySetInnerHTML={{
                      __html: `<div className="">${tagDetails?.TagDetail?.TranslationText} </div>`,
                    }}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="flex w-full flex-col flex-wrap items-center justify-center gap-3 pb-[14px] sm:flex-nowrap">
            <div className="mr-0 flex w-full flex-col sm:mr-3 md:w-4/5">
              {tagDetails?.TagDetail?.AttachmentFileName && (
                <>
                  <div className="mt-2 w-full border border-gray-200" />
                  <div
                    className="my-2 flex w-full cursor-pointer items-center rounded-lg bg-dark-grey pl-3"
                    onClick={(e) => {
                      e.stopPropagation();
                      getAttachmentDownload();
                    }}
                  >
                    <div className="flex w-full items-center py-2">
                      <img src={Images?.AttachY} alt="icon" className="mr-2 w-6 md:w-10" />
                      <p className="w-30 mr-2 flex-1 flex-col justify-center overflow-hidden text-ellipsis text-left text-sm ">
                        {tagDetails?.TagDetail?.AttachmentFileName}
                      </p>
                    </div>
                    <button
                      className="p-2"
                      onClick={(e) => {
                        e.stopPropagation();
                        getAttachmentDownload();
                      }}
                    >
                      <FileDownloadOutlinedIcon className="!h-5 !w-5 !fill-gray-400 transition-colors group-hover:!fill-black group-hover:!text-black" />
                    </button>
                  </div>
                  <div className="w-full border border-gray-200  sm:w-full" />
                </>
              )}

              {(tagDetails?.TagDetail?.TagStatus === 'Open' ||
                tagDetails?.TagDetail?.TagStatus === 'Reply') && (
                <div className="flex h-full w-full flex-col text-start">
                  <label className="pb-1 text-sm font-medium">{t(TEXT?.TAG_TEXT)}</label>

                  <TextArea
                    value={tagText}
                    minHeight={100}
                    className="flex-grow"
                    autoResizeEnabled={true}
                    valueChangeEvent="change"
                    onValueChanged={(e) => setTagText(e?.value)}
                  />

                  <div className="mt-4 flex justify-end gap-2">
                    <button
                      disabled={!tagText}
                      onClick={() => _onEditTag('reply')}
                      className={`${!tagText ? 'bg-gray-300' : 'bg-light-yellow'} flex w-max items-center justify-center rounded-md px-6 py-2`}
                    >
                      <p className="text-sm font-medium text-white sm:text-base">
                        {t(TEXT?.REPLY)}
                      </p>
                    </button>
                    {tagDetails?.TagDetail?.TagStatus === 'Reply' && (
                      <button
                        disabled={!tagText}
                        onClick={() => _onEditTag('close')}
                        className={`${!tagText ? 'bg-gray-300' : 'bg-light-yellow'} flex w-max items-center justify-center rounded-md px-6 py-2`}
                      >
                        <p className="text-sm font-medium text-white sm:text-base">
                          {t(TEXT?.CLOSE)}
                        </p>
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>

          {tagDetails?.TagDetail && (
            <>
              <div className="w-auto border-y-[1px] py-2 text-center">
                <p className="text-font-black-light w-full text-base font-bold sm:text-xl">
                  {t(TEXT?.APPLICATION_DETAILS)}
                </p>
              </div>
              <ApplicationDetails applicationId={tagDetails?.TagDetail?.ApplicationId} />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default TagDetails;
