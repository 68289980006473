import Images from '../config/Images';
import IconImage from '../components/image/IconImage';
import { decryptURL, encryptURL } from './encryptDecryptService';
import { supportedLanguages } from './i18n';

export const getFromAsync = (key) => JSON.parse(localStorage.getItem(key));
export const removeFromAsync = async (key) => localStorage.removeItem(key);
export const setInAsyncStorage = (key, value) => localStorage.setItem(key, JSON.stringify(value));

export const getUrlParams = ({ data }) => decryptURL(data);
export const generateUrl = (path, data) => path + '/' + encryptURL(data);

export const generateLocalizedPath = (lang, route) => {
  const defaultLang = 'en';
  const supportedLang = supportedLanguages?.includes(lang) ? lang : defaultLang;
  return `/${supportedLang}/${route || ''}`;
};

export const getEncodedLastSegmentFromURL = (url) => {
  try {
    const pathSegments = url.split('/').filter(Boolean);
    const encodedString = pathSegments[pathSegments.length - 1];
    return encodedString || null;
  } catch (error) {
    console.error('Error extracting encoded string from resolvedPath:', error);
    return null;
  }
};

export const DownArrowIcon = () => (
  <div>
    <IconImage name={Images.Polygon} isEye width={15} height={15} />
  </div>
);

export const SESSION_KEY = {
  USER_DATA: 'USER_INFO',
  APP_TYPE_ID: 'APP_TYPE_ID',
  I_18_NEXT_LANG: 'i18nextLng',
};

export const ROUTE = {
  LOGIN: '/',
  GO_BACK: -1,
  FORM: 'form',
  PREVIEW: 'preview',
  TAG_LIST: 'tagList',
  REGISTER: 'register',
  DASHBOARD: 'dashboard',
  DOC_PREVIEW: 'documents',
  TAG_DETAILS: 'tagDetails',
  APP_DETAILS: 'applicationDetails',
  GOOGLE_DOC_VIEW: 'googleDocViewer',
};

export const S_TEXT = {
  FORGOT_PASS: 'Forgot Password',
  BLANK_LOGIN_ERROR: 'Please enter email and password',
};

export const TEXT = {
  BY: 'BY',
  URL: 'URL',
  NEW: 'NEW',
  FORM: 'FORM',
  DASH: 'DASH',
  CITY: 'CITY',
  URLS: 'URLS',
  SAVE: 'SAVE',
  LOGIN: 'LOGIN',
  HELLO: 'HELLO',
  EMAIL: 'EMAIL',
  CLOSE: 'CLOSE',
  REPLY: 'REPLY',
  CANCEL: 'CANCEL',
  REJECT: 'REJECT',
  SUBMIT: 'SUBMIT',
  FORGOT: 'FORGOT',
  CHANGE: 'CHANGE',
  SEARCH: 'SEARCH',
  LOGOUT: 'LOGOUT',
  UPDATE: 'UPDATE',
  NEW_TAG: 'NEW_TAG',
  TO_USER: 'TO_USER',
  KEYWORD: 'KEYWORD',
  CREATED: 'CREATED',
  TAG_TEXT: 'TAG_TEXT',
  TAG_LIST: 'TAG_LIST',
  ADDED_BY: 'ADDED_BY',
  READ_MORE: 'READ_MORE',
  DOCUMENTS: 'DOCUMENTS',
  YOUR_PASS: 'YOUR_PASS',
  MARK_TEXT: 'MARK_TEXT',
  DATA_TEXT: 'DATA_TEXT',
  LOGIN_TEXT: 'LOGIN_TEXT',
  SEARCH_FOR: 'SEARCH_FOR',
  OWNER_NAME: 'OWNER_NAME',
  TAG_STATUS: 'TAG_STATUS',
  ADDED_DATE: 'ADDED_DATE',
  TAG_NUMBER: 'TAG_NUMBER',
  TAG_SUMMARY: 'TAG_SUMMARY',
  FORGOT_PASS: 'FORGOT_PASS',
  TAG_DETAILS: 'TAG_DETAILS',
  LAST_30_DAYS: 'LAST_30_DAYS',
  LAST_90_DAYS: 'LAST_90_DAYS',
  MY_OPEN_TAGS: 'MY_OPEN_TAGS',
  REPLIED_TAGS: 'REPLIED_TAGS',
  NO_DATA_FOUND: 'NO_DATA_FOUND',
  BACK_TO_LOGIN: 'BACK_TO_LOGIN',
  LAST_LOGIN_AT: 'LAST_LOGIN_AT',
  DOCUMENTS_LIST: 'DOCUMENTS_LIST',
  APP_BACKGROUND: 'APP_BACKGROUND',
  PAGE_NOT_FOUND: 'PAGE_NOT_FOUND',
  DOCUMENT_NUMBER: 'DOCUMENT_NUMBER',
  BACKGROUND_DOCS: 'BACKGROUND_DOCS',
  DOCUMENT_STATUS: 'DOCUMENT_STATUS',
  DEFAULT_DEADLINE: 'DEFAULT_DEADLINE',
  MARK_KEYWORD_LIST: 'MARK_KEYWORD_LIST',
  REJECT_APPLICATION: 'REJECT_APPLICATION',
  REQUESTED_DEADLINE: 'REQUESTED_DEADLINE',
  APPLICATION_NUMBER: 'APPLICATION_NUMBER',
  THIS_IS_ERROR_PAGE: 'THIS_IS_ERROR_PAGE',
  REPLY_AWAITED_TAGS: 'REPLY_AWAITED_TAGS',
  SELECT_FORM_TO_CONT: 'SELECT_FORM_TO_CONT',
  APPLICATION_DETAILS: 'APPLICATION_DETAILS',
  DOWNLOAD_IN_PROGRESS: 'DOWNLOAD_IN_PROGRESS',
  APP_DATA_UPDATE_MESSAGE: 'APP_DATA_UPDATE_MESSAGE',
};
